import React from "react";

function Footer() {
  const styling = {
    display: "flex",
  };
  return (
    <>
      {" "}
      <div className="align">
        <div className="blacke">
          <div>
            <div style={styling}>
              <i class="fa fa-user"></i>
              <h3>Contact Us</h3>
            </div>
            <div>
              <i class="fa fa-envelope"></i>
              <span>Email: </span>
              <span>elishaagbi04@mail.com</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
