import React from "react";
import "./maincss.css";
import { Link } from "react-router-dom";
function Header() {
  return (
    <>
      <section className="top">
        <div className="logo">
          <span className="p">P</span>
          <span>INE</span>
        </div>
        <div className="header_right">
          <ul>
            <li>
              <i class="fa fa-shopping-bag"></i>
              <Link to="/">Product</Link>
            </li>

            <li>
              <i class="fa fa-address-book"></i>

              <Link to="/developer">Courses</Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Header;
