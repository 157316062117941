import React from "react";
import { Link } from "react-router-dom";
const Developer = () => {
  return (
    <>
      <div className="courses">
        <div className="dean">
          <i class="fa fa-graduation-cap"></i>
          <h3>Courses</h3>
        </div>
        <div className="bim">
          <div className="ya">
            <h2>Confused About Which Passive Income Is Best For You?</h2>
          </div>
          <div className="yu">
            <Link to="/how">
              {" "}
              <button>
                <strong>Know How</strong>
              </button>
            </Link>
          </div>
        </div>
        <div className="bimi">
          <div className="ya">
            <h2>Affiliate Marketing</h2>
            <p>
              Best resource ouline to start Affiliate Marketing and earning
              passively
            </p>
          </div>
          <div className="yu">
            <Link to="/action">
              {" "}
              <button>
                <strong>Start Course</strong>
              </button>
            </Link>
          </div>
        </div>
        <div className="actual"></div>

        <div>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default Developer;
