import React from "react";
import Prod from "./Prod";

const Products = () => {
  return (
    <>
      <Prod />
    </>
  );
};

export default Products;
